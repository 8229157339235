import { sequenceSorter } from 'utils/array';
import {
  IDepositReceiptProgressMetaData,
  IDocument,
  IInprogressDisbursementIssue,
  IInProgressDocumentUpload,
  IIssueCheckDocumentProgressMetaData,
  ILoanAmount,
  IMilestoneDeposit,
  IMultisiteDocumentItem,
  IOrder,
  IOrderBusinessPartiesHash,
  IOrderBusinessParty,
  IOrderCharge,
  IOrderDeposit,
  IOrderDisbursement,
  IOrderHoldFund,
  IOrderPartyContactsHash,
  IOrderProduct,
  IOrderPropertyFullAddress,
  IOrderState,
  IProductionOffice,
  ISalePrice,
  ISettlementDocumentItemProgress,
  OrderAction,
  OrderRequestTypes,
  OrderTypeKeys,
  OrderUIAction,
  AllocationEntityType,
} from './types';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

export const defaultPagination = {
  limit: 10,
  page: 1,
  pages: 1,
  total: 0,
};

export const initialState: IOrderState = {
  affiliatesData: [],
  loading: false,
  requestType: OrderRequestTypes.NONE,
  error: null,
  order: null,
  status: '',
  officeViewSearchValue: '',
  orderPropertyFullAddresses: [] as IOrderPropertyFullAddress[],
  documents: [] as IDocument[],
  documentAssociations: {},
  documentPermissionError: null,
  documentPermissionCandidateParties: [] as IOrderBusinessParty[],
  documentPartiesWithPermissions: [] as IOrderBusinessParty[],
  documentOrganizations: {} as IOrderBusinessPartiesHash,
  documentOrganizationContacts: {} as IOrderBusinessPartiesHash,
  eagleProPackagesError: null,
  eagleProPackagesLatest: null,
  orderOrganizations: {} as IOrderBusinessPartiesHash,
  orderOrganizationContacts: {} as IOrderPartyContactsHash,
  salesPrices: [] as ISalePrice[],
  overdrafts: [],
  overdraftRecoverableTypes: [],
  overdraftCauses: [],
  overdraftRecoverableParties: [],
  multisiteProject: {
    locations: {},
    pagination: defaultPagination,
    sites: [],
  },
  multisiteProjectGeodata: {
    geodata: [],
    unmappableCount: 0,
  },
  multisiteSiteBreadcrumbLinkInfo: {},
  ui: {
    documentPermissions: {
      contactInputIds: [],
    },
    multiDocumentsDownload: {
      orderId: null,
      documentIds: [],
      documentArchiveFilesize: 0,
      downloadPromptVisible: false,
    },
    removingDocumentId: null,
    singleDocumentDownload: {
      documentIds: [],
      totalFileSize: 0,
    },
    multiDocumentsUpload: {
      stagedDocuments: [],
      inProgressUploads: [],
      finishedUploads: [],
    },
    multiSitesRemoval: {
      projectFileId: null,
      siteFileIds: [],
    },
    depositReceiptsProgress: {
      receiptsMetaData: [],
    },
    issueCheckDocumentProgress: {
      items: [],
    },

    settlementDocumentProgress: {
      items: [],
    },
    multisiteSettlementDocuments: {
      error: false,
      items: [],
    },
    bulkUpdatesStatusProgress: {
      siteOrders: [],
      isLoanAmountAllocation: false,
      fetchOrderDetailsOnFinish: false,
    },
    bulkAllocationsUpdatesStatusProgress: {
      entityType: AllocationEntityType.None,
      orderId: null,
      jobId: null,
      entities: [],
      jobResult: [],
    },
    deleteOrderLoanProgress: {
      items: [],
      fetchOrderDetailsOnFinish: false,
    },
    disbursementIssueProgress: {
      stagedIssues: [],
      inProgressIssues: [],
      progressValue: 0,
    },
  },
  isDMSEnabled: false,
  canAccessCustomerDocumentUpload: false,
};

export default (
  state = initialState,
  action: OrderAction | OrderUIAction
): IOrderState => {
  switch (action.type) {
    case OrderTypeKeys.REQUEST:
      return {
        ...state,
        loading: true,
        requestType: action.requestType,
      };

    case OrderTypeKeys.SUCCESS:
      return {
        ...state,
        loading: false,
        requestType: OrderRequestTypes.NONE,
        error: null,
      };

    case OrderTypeKeys.SET_AFFILIATES_DATA:
      return {
        ...state,
        affiliatesData: action.affiliatesData,
      };

    case OrderTypeKeys.SET_FILTER_CARD_STATUS:
      return {
        ...state,
        status: action.status,
      };

    case OrderTypeKeys.SET_OFFICE_VIEW_SEARCH_VALUE:
      return {
        ...state,
        officeViewSearchValue: action.officeViewSearchValue,
      };

    case OrderTypeKeys.SET_CAN_ACCESS_CUSTOMER_DOCUMENT_UPLOAD:
      return {
        ...state,
        canAccessCustomerDocumentUpload: action.canAccessCustomerDocumentUpload,
      };

    case OrderTypeKeys.ERROR:
      return {
        ...state,
        loading: false,
        requestType: OrderRequestTypes.NONE,
        error: action.error,
      };

    case OrderTypeKeys.RECEIVE_ORDER_RESULTS:
      let order = action.order;

      if (order) {
        order = {
          ...order,
          loanAmounts: [...order.loanAmounts].sort(sequenceSorter),
          assumptionLoans: [...order.assumptionLoans].sort(sequenceSorter),
          ownerLiabilities: [...order.ownerLiabilities].sort(sequenceSorter),
        };

        if (state.order?.holdFunds) {
          order.holdFunds = state.order.holdFunds;
        }

        if (state.order?.disbursementInfo) {
          order.disbursementInfo = state.order.disbursementInfo;
        }

        if (action.updateOrderDataOnly) {
          order.products = state.order?.products || [];
          order.titleProductionOffices =
            state.order?.titleProductionOffices || [];
          order.parties = state.order?.parties || [];
          order.escrowProductionOffices =
            state.order?.escrowProductionOffices || [];
          order.otherParties = state.order?.otherParties || [];
          order.orderParties = state.order?.orderParties || [];
        }
      }

      return {
        ...state,
        order,
      };

    case OrderTypeKeys.RECEIVE_ORDER_OWNING_OFFICES_RESULTS: {
      const owningOffices = action.owningOffices;
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          offices: owningOffices,
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_DEPOSITS_SUCCESS: {
      const data = action.deposits || ([] as IOrderDeposit[]);

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          deposits: { data },
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_DEPOSITS_ERROR: {
      const { error } = action;

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          deposits: { error },
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_MILESTONE_DEPOSITS_SUCCESS: {
      const milestoneData =
        action.milestoneDeposits || ([] as IMilestoneDeposit[]);
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          milestoneDeposits: { milestoneData },
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_MILESTONE_DEPOSITS_ERROR: {
      const { error } = action;

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          milestoneDeposits: { error },
        },
      };
    }

    case OrderTypeKeys.SET_ORDER_DEPOSIT_RECEIPT_PROGRESS: {
      const data =
        action.receiptsMetaData || ([] as IDepositReceiptProgressMetaData[]);

      let newReceiptState = [
        ...state.ui.depositReceiptsProgress.receiptsMetaData,
      ];

      if (newReceiptState.length) {
        data.forEach(function (receipt) {
          var index = newReceiptState.findIndex(
            (x) => x.depositId === receipt.depositId
          );
          if (index >= 0) {
            newReceiptState[index].documentId = receipt.documentId;
          } else {
            newReceiptState = newReceiptState.concat(data);
          }
        });
      } else {
        newReceiptState.push(...data);
      }

      return {
        ...state,
        ui: {
          ...state.ui,
          depositReceiptsProgress: {
            receiptsMetaData: newReceiptState,
          },
        },
      };
    }

    case OrderTypeKeys.SET_ISSUE_CHECK_DOCUMENT_PROGRESS: {
      const data =
        action.issueCheckMetaData ||
        ([] as IIssueCheckDocumentProgressMetaData[]);

      let previousIssueCheckState = [
        ...state.ui.issueCheckDocumentProgress.items,
      ];

      if (previousIssueCheckState.length) {
        //PATCH the existing state with new state data.
        data.forEach((item) => {
          const indexInOldData = previousIssueCheckState.findIndex(
            (prev) =>
              prev.checks.map((x) => x.disbursementId).join('-') ===
              item.checks.map((x) => x.disbursementId).join('-')
          );
          if (indexInOldData >= 0) {
            previousIssueCheckState[indexInOldData] = {
              ...previousIssueCheckState[indexInOldData],
              ...item,
              deliveryStatus: item.deliveryStatus,
              errored: item.errored,
            };
          } else {
            previousIssueCheckState = [...previousIssueCheckState, item];
          }
        });
      } else {
        previousIssueCheckState.push(...data);
      }

      return {
        ...state,
        ui: {
          ...state.ui,
          issueCheckDocumentProgress: {
            items: previousIssueCheckState,
          },
        },
      };
    }

    case OrderTypeKeys.CLEAR_ISSUE_CHECK_DOCUMENT_PROGRESS: {
      const data = action.issueCheckMetaData;

      const updatedMetaData = !data
        ? []
        : state.ui.issueCheckDocumentProgress.items.filter(
            (item) =>
              item.checks.map((x) => x.disbursementId).join('-') !==
              data.checks.map((x) => x.disbursementId).join('-')
          );
      return {
        ...state,
        ui: {
          ...state.ui,
          issueCheckDocumentProgress: {
            items: updatedMetaData,
          },
        },
      };
    }

    case OrderTypeKeys.SET_SETTLEMENT_DOCUMENT_PROGRESS: {
      const data = action.items || ([] as ISettlementDocumentItemProgress[]);
      let prevState = [...state.ui.settlementDocumentProgress.items];
      if (prevState.length) {
        // PATCH the existing state with new state data.
        data.forEach((item) => {
          const indexInOldData = prevState.findIndex((prev) => {
            if (!prev.previewId) return prev.orderId === item.orderId;
            return prev.previewId === item.previewId;
          });
          if (indexInOldData >= 0) {
            prevState[indexInOldData] = {
              ...prevState[indexInOldData],
              ...item,
              status: item.status,
              errored: item.errored,
            };
          } else {
            prevState = [...prevState, item];
          }
        });
      } else {
        prevState.push(...data);
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          settlementDocumentProgress: {
            items: prevState,
          },
        },
      };
    }

    case OrderTypeKeys.MULTISITE_ORDER_SET_SETTLEMENT_DOCUMENT_PROGRESS: {
      const data = action.items || ({} as IMultisiteDocumentItem);
      let prevState = [...state.ui.multisiteSettlementDocuments.items];
      const item = data[0];

      if (prevState.length) {
        if (item) {
          // Avoid duplicates...
          const uniqueDocs = prevState.filter((i) => i.id !== item.id);
          prevState = [...uniqueDocs, item];
        } else {
          prevState = [];
        }
      } else {
        prevState = [...data];
      }

      return {
        ...state,
        ui: {
          ...state.ui,
          multisiteSettlementDocuments: {
            error: false,
            items: prevState,
          },
        },
      };
    }

    case OrderTypeKeys.CLEAR_SETTLEMENT_DOCUMENT_PROGRESS: {
      const data = action.item;
      let updatedMetaData: ISettlementDocumentItemProgress[] = [];
      if (data) {
        updatedMetaData = state.ui.settlementDocumentProgress.items.filter(
          (item) => item.previewId !== data.previewId
        );
      }
      return {
        ...state,
        ui: {
          ...state.ui,
          settlementDocumentProgress: {
            items: updatedMetaData,
          },
        },
      };
    }

    case OrderTypeKeys.MULTISITE_ORDER_CLEAR_SETTLEMENT_DOCUMENT_PROGRESS: {
      const data = action.item;
      let updatedMetaData: IMultisiteDocumentItem[] = [];
      let error = false;

      if (data?.id) {
        updatedMetaData = state.ui.multisiteSettlementDocuments.items.filter(
          (item) => item.id !== data.id
        );
      } else {
        error = true;
        updatedMetaData = [];
      }

      return {
        ...state,
        ui: {
          ...state.ui,
          multisiteSettlementDocuments: {
            error,
            items: updatedMetaData,
          },
        },
      };
    }

    case OrderTypeKeys.CLEAR_ORDER_DEPOSIT_RECEIPT_PROGRESS: {
      const data = action.receiptMetaData;
      const updatedReceiptsMetaData = !data
        ? []
        : state.ui.depositReceiptsProgress.receiptsMetaData.filter(
            (receipt) => receipt.depositId !== data.depositId
          );
      return {
        ...state,
        ui: {
          ...state.ui,
          depositReceiptsProgress: {
            receiptsMetaData: updatedReceiptsMetaData,
          },
        },
      };
    }

    case OrderTypeKeys.DELETE_ORDER_DISBURSEMENT_SUCCESS: {
      const data = action.disbursementMetaData;
      const updatedDisbursements = (
        state?.order?.disbursements || ([] as IOrderDisbursement[])
      ).filter((d) => d.fileProcessId !== data.fileProcessId);
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          disbursements: updatedDisbursements,
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_DISBURSEMENTS_SUCCESS: {
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          disbursements: action.disbursements || ([] as IOrderDisbursement[]),
        },
      };
    }

    case OrderTypeKeys.REPLACE_ORDER_DISBURSEMENT_ID: {
      const updatedDisbursements = (
        state?.order?.disbursements || ([] as IOrderDisbursement[])
      ).map((d) => {
        if (d.id === action.oldId) {
          d.id = action.newId;
        }
        return d;
      });
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          disbursements: updatedDisbursements,
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_CHARGES_SUCCESS: {
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          charges: action.charges || ([] as IOrderCharge[]),
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_HOLD_FUNDS_SUCCESS: {
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          holdFunds: action.holdFunds || ([] as IOrderHoldFund[]),
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_DISBURSEMENT_INFO_SUCCESS: {
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          disbursementInfo:
            action.disbursementInfo || ({} as IOrderDisbursement),
        },
      };
    }

    case OrderTypeKeys.REQUEST_ORDER_OVERDRAFTS_SUCCESS: {
      return {
        ...state,
        overdrafts: action.overdrafts || [],
      };
    }

    case OrderTypeKeys.REQUEST_OVERDRAFTS_RECOVERABLE_TYPES_SUCCESS: {
      return {
        ...state,
        overdraftRecoverableTypes: action.overdraftRecoverableTypes || [],
      };
    }

    case OrderTypeKeys.REQUEST_OVERDRAFTS_CAUSES_SUCCESS: {
      return {
        ...state,
        overdraftCauses: action.overdraftCauses || [],
      };
    }

    case OrderTypeKeys.REQUEST_OVERDRAFTS_RECOVERABLE_PARTIES_SUCCESS: {
      return {
        ...state,
        overdraftRecoverableParties: action.overdraftRecoverableParties || [],
      };
    }

    case OrderTypeKeys.RECEIVE_ORDER_PRODUCTS_RESULTS: {
      const products = action.products || ([] as IOrderProduct[]);

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          products,
        },
      };
    }

    case OrderTypeKeys.RECEIVE_ORDER_BUSINESS_PARTIES_RESULTS: {
      const parties = action.parties || ([] as IOrderBusinessParty[]);

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          parties,
        },
      };
    }

    case OrderTypeKeys.RECEIVE_ORDER_TITLE_PRODUCTION_OFFICES_RESULTS: {
      const titleProductionOffices =
        action.titleProductionOffices || ([] as IProductionOffice[]);
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          titleProductionOffices,
        },
      };
    }

    case OrderTypeKeys.RECEIVE_ORDER_ESCROW_PRODUCTION_OFFICES_RESULTS: {
      const escrowProductionOffices =
        action.escrowProductionOffices || ([] as IProductionOffice[]);
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          escrowProductionOffices,
        },
      };
    }

    case OrderTypeKeys.RECEIVE_ORDER_PARTIES_RESULTS: {
      const orderParties = action.orderParties || ([] as IOrderBusinessParty[]);

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          orderParties,
        },
      };
    }

    case OrderTypeKeys.RECEIVE_ORDER_OTHER_PARTIES_RESULTS:
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          otherParties: action.otherParties || ([] as IOrderBusinessParty[]),
        },
      };

    case OrderTypeKeys.RECEIVE_ORDER_OTHER_BROKER_PARTIES_RESULTS:
      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          otherBrokers:
            action.otherBrokerParties || ([] as IOrderBusinessParty[]),
        },
      };

    case OrderTypeKeys.CLEAR:
      return {
        ...initialState,
        multisiteSiteBreadcrumbLinkInfo: {
          ...state.multisiteSiteBreadcrumbLinkInfo,
        },
        ui: {
          ...initialState.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
          },
          // We need the UI state for selected documents
          // to persist even when we are clearing the order state.
          multiDocumentsDownload: {
            ...state.ui.multiDocumentsDownload,
          },
          depositReceiptsProgress: {
            ...state.ui.depositReceiptsProgress,
          },
          issueCheckDocumentProgress: {
            ...state.ui.issueCheckDocumentProgress,
          },
          settlementDocumentProgress: {
            ...state.ui.settlementDocumentProgress,
          },
          multisiteSettlementDocuments: {
            ...state.ui.multisiteSettlementDocuments,
          },
          bulkUpdatesStatusProgress: {
            ...state.ui.bulkUpdatesStatusProgress,
          },
          bulkAllocationsUpdatesStatusProgress: {
            ...state.ui.bulkAllocationsUpdatesStatusProgress,
          },
          deleteOrderLoanProgress: {
            ...state.ui.deleteOrderLoanProgress,
          },
        },
      };

    case OrderTypeKeys.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    case OrderTypeKeys.RECEIVE_ORDER_DOCUMENTS:
      return {
        ...state,
        documents: action.documents || [],
        isDMSEnabled: action.isDMSEnabled || false,
      };

    case OrderTypeKeys.UPDATE_ORDER_DOCUMENT: {
      const updatedDocuments = state.documents.map((doc) => {
        if (doc.id === action.document.id) return action.document;
        return doc;
      });
      return {
        ...state,
        documents: updatedDocuments,
      };
    }

    case OrderTypeKeys.APPEND_ORDER_DOCUMENT: {
      const partialFieldCheck = (obj: IDocument) => {
        return pick(obj, [
          'fileId',
          'name',
          'displayName',
          'subType',
          'fileSize',
          'documentType',
        ]);
      };
      const partialDocument = partialFieldCheck(action.document);

      const objIdx = state.documents.findIndex((doc) =>
        isEqual(partialFieldCheck(doc), partialDocument)
      );

      if (objIdx > -1) {
        const copyDocuments = [...state.documents];
        copyDocuments.splice(objIdx, 0, action.appendDoc);
        return { ...state, documents: copyDocuments };
      }
      return { ...state };
    }

    case OrderTypeKeys.REMOVE_CACHED_ORDER_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(
          (doc) => doc.id !== Number(action.removedDocumentId)
        ),
        ui: {
          ...state.ui,
          removingDocumentId: null,
        },
      };

    case OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_CONTENT: {
      const documentIndex = state.documents.findIndex(
        (x) =>
          x.id === Number(action.documentId) ||
          x.templateInstanceId === action.documentId
      );

      if (documentIndex === -1) {
        return state;
      }

      const documents = [
        ...state.documents.slice(0, documentIndex),
        {
          ...state.documents[documentIndex],
          documentContent: action.file,
        },
        ...state.documents.slice(documentIndex + 1),
      ];
      return {
        ...state,
        documents,
      };
    }

    case OrderTypeKeys.DOCUMENT_ERROR:
      return {
        ...state,
      };

    case OrderTypeKeys.DOCUMENT_PERMISSIONS_ERROR:
      return {
        ...state,
        documentPermissionError: action.error,
      };

    case OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_PARTIES_WITH_PERMISSION:
      return {
        ...state,
        documentPartiesWithPermissions: action.parties,
      };

    case OrderTypeKeys.RECEIVE_ORDER_DOCUMENT_PERMISSION_CANDIDATES:
      return {
        ...state,
        documentPermissionCandidateParties: action.parties,
      };

    case OrderTypeKeys.SET_DOC_ORGANIZATIONS:
      return {
        ...state,
        documentOrganizations: action.organizations,
      };

    case OrderTypeKeys.SET_DOC_ORGANIZATION_CONTACTS:
      return {
        ...state,
        documentOrganizationContacts: action.contacts,
      };

    case OrderTypeKeys.ADD_DOC_PARTY_TO_PARTIES_WITH_PERMISSIONS:
      return {
        ...state,
        documentPartiesWithPermissions: [
          action.party,
          ...state.documentPartiesWithPermissions,
        ],
      };

    case OrderTypeKeys.EP_PACKAGE_ERROR:
      return {
        ...state,
        eagleProPackagesError: action.error,
      };

    case OrderTypeKeys.EP_PACKAGE_SET_LATEST:
      return {
        ...state,
        eagleProPackagesLatest: action.package,
      };

    case OrderTypeKeys.SET_ORDER_ORGANIZATIONS:
      return {
        ...state,
        orderOrganizations: action.organizations,
      };

    case OrderTypeKeys.SET_ORDER_ORGANIZATION_CONTACTS:
      return {
        ...state,
        orderOrganizationContacts: action.contacts,
      };

    case OrderTypeKeys.REMOVE_ORDER_PARTY_CONTACT: {
      const excludePartyById = (
        parties: IOrderBusinessParty[],
        id: number
      ): IOrderBusinessParty[] =>
        parties.filter(
          (c: IOrderBusinessParty) => c.id !== id || c.partyId !== id
        );

      if (state.order === null) return state;

      return {
        ...state,
        order: {
          ...state.order,
          orderParties: excludePartyById(
            state.order.orderParties,
            action.contactId
          ),
          otherParties: excludePartyById(
            state.order.otherParties,
            action.contactId
          ),
        },
      };
    }

    case OrderTypeKeys.REVOKE_ORDER_ACCESS: {
      const parties = action.parties || ([] as IOrderBusinessParty[]);

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          parties,
        },
      };
    }

    case OrderTypeKeys.GRANT_ORDER_ACCESS: {
      const parties = action.parties || ([] as IOrderBusinessParty[]);

      return {
        ...state,
        order: {
          ...(state.order || ({} as IOrder)),
          parties,
        },
      };
    }

    case OrderTypeKeys.REMOVE_ORDER_PARTY_EMPLOYEE: {
      const parties = state.order?.parties?.filter(
        (p: IOrderBusinessParty) =>
          p.employeeId !== action.employeeId ||
          p.businessPartyRoleId !== action.roleId
      );
      return {
        ...state,
        order: {
          ...(state.order as IOrder),
          parties,
        },
      };
    }

    // UI Multisite Reducers
    case OrderTypeKeys.MULTISITE_PROJECT_SET_SITES:
      return {
        ...state,
        multisiteProject: {
          ...state.multisiteProject,
          locations: {
            ...(action.payload.locations ?? {}),
          },
          pagination: {
            ...defaultPagination,
            ...action.payload.pagination,
          },
          sites:
            action.payload.pagination.page === 1
              ? action.payload.sites
              : [...state.multisiteProject.sites, ...action.payload.sites] ||
                [],
        },
      };

    case OrderTypeKeys.MULTISITE_PROJECT_SET_GEODATA:
      return {
        ...state,
        multisiteProjectGeodata: {
          geodata: action.payload.geodata,
          unmappableCount: action.payload.unmappableCount,
        },
      };

    case OrderTypeKeys.MULTISITE_PROJECT_SET_BREADCRUMB_INFO: {
      return {
        ...state,
        multisiteSiteBreadcrumbLinkInfo: {
          ...state.multisiteSiteBreadcrumbLinkInfo,
          [action.payload.multisiteSiteId]: {
            ...action.payload.linkInfo,
          },
        },
      };
    }

    // UI Reducers
    case OrderTypeKeys.UI_SET_REMOVING_DOCUMENT:
      return {
        ...state,
        ui: {
          ...state.ui,
          removingDocumentId: action.removingDocumentId,
        },
      };
    case OrderTypeKeys.UI_SET_MUTLI_DOCS_ORDER_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsDownload: {
            ...state.ui.multiDocumentsDownload,
            orderId: action.orderId,
          },
        },
      };

    case OrderTypeKeys.UI_SET_MUTLI_DOCS_DOCUMENT_IDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsDownload: {
            ...state.ui.multiDocumentsDownload,
            documentIds: action.documentIds,
            documentArchiveFilesize: action.documentArchiveFilesize,
          },
        },
      };

    case OrderTypeKeys.UI_SET_MULTISITE_SELECTION:
      return {
        ...state,
        ui: {
          ...state.ui,
          multiSitesRemoval: {
            ...state.ui.multiSitesRemoval,
            siteFileIds: action.siteFileIds,
          },
        },
      };

    case OrderTypeKeys.UI_SET_MUTLI_DOCS_PROMPT_VISIBILITY:
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsDownload: {
            ...state.ui.multiDocumentsDownload,
            downloadPromptVisible: action.show,
          },
        },
      };

    case OrderTypeKeys.UI_SET_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          singleDocumentDownload: {
            documentIds: state.ui.singleDocumentDownload.documentIds.concat(
              action.documentId
            ),
            totalFileSize:
              state.ui.singleDocumentDownload.totalFileSize + action.fileSize,
          },
        },
      };

    case OrderTypeKeys.UI_CLEAR_SINGLE_DOC_DOWNLOAD_DOCUMENT_ID:
      return {
        ...state,
        ui: {
          ...state.ui,
          singleDocumentDownload: {
            documentIds: state.ui.singleDocumentDownload.documentIds.filter(
              (documentId) => documentId !== action.documentId
            ),
            totalFileSize:
              state.ui.singleDocumentDownload.totalFileSize - action.fileSize,
          },
        },
      };

    case OrderTypeKeys.UI_SET_DOC_PERMISSIONS_CONTACT_INPUT_IDS:
      return {
        ...state,
        ui: {
          ...state.ui,
          documentPermissions: {
            contactInputIds: action.ids,
          },
        },
      };

    case OrderTypeKeys.UI_STAGE_DOCUMENT_UPLOADS:
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
            stagedDocuments: state.ui.multiDocumentsUpload.stagedDocuments.concat(
              action.documentsToStage
            ),
          },
        },
      };

    case OrderTypeKeys.UI_QUEUE_DISBURSEMENT_ISSUE: {
      const { inProgressIssues } = state.ui.disbursementIssueProgress;
      const { stagedIssues } = action;
      return {
        ...state,
        ui: {
          ...state.ui,
          disbursementIssueProgress: {
            ...state.ui.disbursementIssueProgress,
            inProgressIssues: inProgressIssues.concat(
              (stagedIssues.map((doc) => ({
                ...doc,
                progressValue: 0,
              })) as unknown) as IInprogressDisbursementIssue[]
            ),
          },
        },
      };
    }

    case OrderTypeKeys.UI_UPDATE_DISBURSEMENT_ISSUE_PROGRESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          disbursementIssueProgress: {
            ...state.ui.disbursementIssueProgress,
            progressValue: action.progressValue,
          },
        },
      };
    }

    case OrderTypeKeys.UI_CLEAR_ACTIVE_DISBURSEMENT_ISSUES: {
      return {
        ...state,
        ui: {
          ...state.ui,
          disbursementIssueProgress: {
            ...state.ui.disbursementIssueProgress,
            inProgressIssues: [],
            progressValue: 0,
          },
        },
      };
    }

    case OrderTypeKeys.UI_CLEAR_STAGED_DOCUMENT_UPLOADS:
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
            stagedDocuments: [],
          },
        },
      };

    case OrderTypeKeys.UI_CLEAR_ACTIVE_UPLOAD_DOCUMENTS:
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
            inProgressUploads: [],
            finishedUploads: [],
          },
        },
      };

    case OrderTypeKeys.UI_QUEUE_ORDER_DOCUMENT_UPLOAD: {
      const { inProgressUploads } = state.ui.multiDocumentsUpload;
      const { stagedDocuments } = action;

      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
            inProgressUploads: inProgressUploads.concat(
              stagedDocuments.map((doc) => ({
                ...doc,
                progress: 0,
              })) as IInProgressDocumentUpload[]
            ),
          },
        },
      };
    }

    case OrderTypeKeys.UI_UPDATE_DOC_UPLOAD_PROGRESS: {
      const { inProgressUploads } = state.ui.multiDocumentsUpload;
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
            inProgressUploads: inProgressUploads.map((item) =>
              item.file.name === action.fileName
                ? { ...item, progress: action.progress }
                : item
            ),
          },
        },
      };
    }

    case OrderTypeKeys.UI_COMPLETE_ACTIVE_DOCUMENT_UPLOAD: {
      const {
        inProgressUploads,
        finishedUploads,
      } = state.ui.multiDocumentsUpload;
      const index = inProgressUploads.findIndex(
        (i) => i.file.name === action.fileName
      );
      const { progress, ...finishedUpload } = inProgressUploads[index];
      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
            inProgressUploads: inProgressUploads.filter(
              (i) => i.file.name !== action.fileName
            ),
            finishedUploads: finishedUploads.concat({
              ...finishedUpload,
              error: action.error,
            }),
          },
        },
      };
    }

    case OrderTypeKeys.UI_REMOVE_SUCCESSFUL_UPLOADS: {
      const { finishedUploads } = state.ui.multiDocumentsUpload;

      return {
        ...state,
        ui: {
          ...state.ui,
          multiDocumentsUpload: {
            ...state.ui.multiDocumentsUpload,
            inProgressUploads: [],
            finishedUploads: finishedUploads.filter((u) => !!u.error),
          },
        },
      };
    }

    case OrderTypeKeys.UPDATE_ORDER_NAME_SUCCESS: {
      if (state.order?.fileID !== action.fileId) return state;
      return {
        ...state,
        order: {
          ...state.order,
          name: action.name,
        },
      };
    }

    case OrderTypeKeys.UPDATE_ORDER_CLOSE_DATE_SUCCESS: {
      if (state.order?.fileID !== action.fileId) return state;
      return {
        ...state,
        order: {
          ...state.order,
          closeDate: action.closeDate,
          isCloseDateEstimated: action.isCloseDateEstimated,
        },
      };
    }

    case OrderTypeKeys.SET_ORDER_PROPERTIES_ADDRESSES: {
      return {
        ...state,
        orderPropertyFullAddresses: action.payload.addresses,
      };
    }

    case OrderTypeKeys.SET_ORDER_ADDRESSES: {
      return {
        ...state,
        order: {
          ...state.order,
          addresses: action.payload.addresses ?? [],
        } as IOrder,
      };
    }

    case OrderTypeKeys.UPDATE_ORDER_LOAN_AND_LIABILITY: {
      if (state.order?.fileID !== action.fileId) {
        return state;
      }

      const updatedLoanAmountsHt: Record<string, ILoanAmount> = {};

      action.updatedLoanAmounts.forEach((loan) => {
        updatedLoanAmountsHt[loan.id] = loan;
      });

      const newLoanAmounts = state.order.loanAmounts
        .map((loan) => {
          if (updatedLoanAmountsHt[loan.id])
            return updatedLoanAmountsHt[loan.id];

          return loan;
        })
        .sort(sequenceSorter);

      return {
        ...state,
        order: {
          ...state.order,
          loanAmounts: newLoanAmounts,
        },
      };
    }

    case OrderTypeKeys.UPDATE_ORDER_PRODUCTS_SUCCESS: {
      if (state.order?.fileID !== action.fileId) {
        return state;
      }
      return {
        ...state,
        order: {
          ...state.order,
          products: action.products,
        },
      };
    }

    case OrderTypeKeys.RECEIVE_SALES_PRICE: {
      const { salesPrices, totalSalesPrice } = action.payload;

      const orderedSalesPrices = [...salesPrices].sort(sequenceSorter);
      const order =
        totalSalesPrice !== undefined
          ? ({
              ...state.order,
              totalSalesPrice,
            } as IOrder)
          : state.order;

      return {
        ...state,
        order,
        salesPrices: orderedSalesPrices,
      };
    }

    case OrderTypeKeys.RECEIVE_OWNER_LIABILITIES: {
      if (state.order) {
        const ownerLiabilities = [...action.payload.ownerLiabilities].sort(
          sequenceSorter
        );

        return {
          ...state,
          order: {
            ...state.order,
            ownerLiabilities,
          },
        };
      }

      return state;
    }

    case OrderTypeKeys.RECEIVE_ORDER_STATUS: {
      if (state.order) {
        const { status } = action.payload;

        return {
          ...state,
          order: {
            ...state.order,
            status,
          },
        };
      }

      return state;
    }

    case OrderTypeKeys.UPDATE_ORDER_SERVICES_COMPLETE: {
      if (state.order?.fileID !== action.fileId) {
        return state;
      }
      return {
        ...state,
        order: {
          ...state.order,
          services: [...action.services],
          offices: [...(action.offices || state.order.offices)],
        },
      };
    }

    case OrderTypeKeys.UPDATE_ORDER_TYPE_SUCCESS: {
      if (state.order?.fileID !== action.fileId) {
        return state;
      }
      return {
        ...state,
        order: {
          ...state.order,
          transactionType: action.transactionType,
        },
      };
    }

    case OrderTypeKeys.SET_BULK_UPDATES_STATUS_PROGRESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          bulkUpdatesStatusProgress: action.bulkUpdatesStatusProgress,
        },
      };
    }

    case OrderTypeKeys.SET_BULK_ALLOCATIONS_UPDATES_STATUS_PROGRESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          bulkAllocationsUpdatesStatusProgress:
            action.bulkAllocationsUpdatesStatusProgress,
        },
      };
    }

    case OrderTypeKeys.SET_DELETE_ORDER_LOAN_STATUS_PROGRESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          deleteOrderLoanProgress: action.deleteOrderLoanProgress,
        },
      };
    }

    default:
      return state;
  }
};
