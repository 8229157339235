import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lightBlue, black, blue } from 'config/theme';
import {
  PdfIcon,
  HelpIcon,
  VideoIcon,
  LightBulbOnIcon,
  StarCircleIcon,
} from 'components/svg/legacyIcons_DO_NOT_USE';
import { setClarityFirstVideo } from 'store/resources/actions';
import {
  STATE_GUIDE_TO_REAL_ESTATE_LAWS,
  ALTA_ENDORSEMENT_GUIDE,
  TITLE_CAMP_WEBINARS,
} from 'store/resources/constants';
import { UserType } from 'store/auth/types';
import { FeatureToggle } from 'components/Features';
import { PlatformFeatures } from 'store/features/types';
import { push } from 'connected-react-router';
import { DropdownRowContainer, Header, IconContainer, Title } from '../Common';
import { Dispatch } from 'store';

interface IResourcesDropdownProps {
  userType: UserType;
  onResourceClick: () => void;
  dispatch: Dispatch;
}

export class ResourcesDropdown extends React.Component<
  IResourcesDropdownProps
> {
  public render() {
    const { onResourceClick, userType, dispatch } = this.props;

    return (
      <>
        <Header>
          <Title>Featured Resources</Title>
        </Header>
        <DropdownRowContainer
          href={STATE_GUIDE_TO_REAL_ESTATE_LAWS}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onResourceClick}
        >
          <IconContainer>
            <PdfIcon />
          </IconContainer>
          <Description>State Guide to Real Estate Laws & Customs</Description>
        </DropdownRowContainer>
        <DropdownRowContainer
          href={ALTA_ENDORSEMENT_GUIDE}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onResourceClick}
          id="resource-menu__ALTA-endorsement-guide-option"
        >
          <IconContainer>
            <PdfIcon />
          </IconContainer>
          <Description>2024 ALTA Endorsement Guide</Description>
        </DropdownRowContainer>
        <DropdownRowContainer onClick={this.handleVideoClick}>
          <IconContainer>
            <VideoIcon />
          </IconContainer>
          <Description style={{ paddingTop: 4 }}>
            The Power of ClarityFirst
            <SuperSymbols>&reg; </SuperSymbols> - Welcome Video
          </Description>
        </DropdownRowContainer>
        <FeatureToggle
          flag={
            userType === UserType.Internal
              ? PlatformFeatures.WhatsNewInternal
              : PlatformFeatures.WhatsNewExternal
          }
        >
          <DropdownRowContainer
            id="titleCamp"
            onClick={() => {
              onResourceClick();
              window.open(TITLE_CAMP_WEBINARS);
            }}
          >
            <IconContainer>
              <StarCircleIcon fill={blue} />
            </IconContainer>
            <Description style={{ paddingTop: 4 }}>
              Title Camp Webinars
            </Description>
          </DropdownRowContainer>
        </FeatureToggle>
        <FeatureToggle flag={PlatformFeatures.LearnIt}>
          <DropdownRowContainer
            id="LearnItTab"
            onClick={() => {
              onResourceClick();
            }}
          >
            <IconContainer>
              <LightBulbOnIcon />
            </IconContainer>
            <Description>Learning and Feedback</Description>
          </DropdownRowContainer>
        </FeatureToggle>
        <DropdownRowContainer
          id="help-tab"
          onClick={() => {
            onResourceClick();
            dispatch(push('/help'));
          }}
        >
          <IconContainer>
            <HelpIcon style={{ width: 22, height: 22 }} />
          </IconContainer>
          <Description id="resource-dropdown_help">Help</Description>
        </DropdownRowContainer>
        <Footer>
          <Link to="/resources" onClick={onResourceClick}>
            View All Resources
          </Link>
        </Footer>
      </>
    );
  }

  private handleVideoClick = () => {
    this.props.onResourceClick();
    this.props.dispatch(setClarityFirstVideo(true));
  };
}

const connected = connect()(ResourcesDropdown);
connected.displayName = 'ResourcesDropdown';
export default connected;

const Footer = styled.div`
  align-items: center;
  color: ${lightBlue};
  display: flex;
  height: 50px;
  justify-content: center;
  & > a {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
  }
`;

const Description = styled.div`
  color: ${black};
  padding: 7px 0px 8px 14px;
`;
Description.displayName = 'Description';

const SuperSymbols = styled.span`
  font-size: 12px;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.005em;
  vertical-align: super;
`;
SuperSymbols.displayName = 'SuperSymbols';
