import { black, white } from 'config/colors';
import {
  createTheme,
  makeStyles,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import { SUB_TEXT_BOOK } from 'config/typography';
import React, { FunctionComponent } from 'react';
import { SHADOW_8P } from 'config/shadows';

interface IMtooltipProps {
  color?: 'light' | 'dark';
  maxWidth?: string;
  tooltipMargin?: string;
  paddingScale?: number; // MUI theme spacing is based on 8px spacing scale so paddingScale = 1 means 8px padding
}

const MTooltip: FunctionComponent<TooltipProps & IMtooltipProps> = ({
  children,
  color = 'dark',
  enterDelay = 1000,
  maxWidth = '400px',
  paddingScale = 1,
  tooltipMargin,
  ...props
}) => {
  const theme = createTheme();

  const tooltipStyles = makeStyles(() => ({
    customTooltip: {
      backgroundColor: color === 'light' ? white : black,
      color: color === 'light' ? black : white,
      maxWidth: maxWidth,
      margin: tooltipMargin,
      padding: theme.spacing(paddingScale),
      ...SHADOW_8P,
      ...SUB_TEXT_BOOK,
    },
    customCursor: {
      cursor: 'pointer',
    },
  }));

  const classes = tooltipStyles();

  return (
    <Tooltip
      {...props}
      classes={{ tooltip: classes.customTooltip }}
      enterDelay={enterDelay}
      className={classes.customCursor}
    >
      {children}
    </Tooltip>
  );
};

export default MTooltip;
