import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { headerZIndex, white } from 'config/theme';
import {
  primaryBlue2,
  primaryBlue9,
  gray8,
  gray10,
  neutralGray7,
  yellow4,
  primaryBlue8,
  green8,
} from 'config/colors';
import { Column, Row } from 'components/ui/Box';
import { Spinner } from 'components/ui/Spinner/Spinner';
import { default as Truncate } from 'components/ui/Truncate';
import { SMALL_BODY_MEDIUM, SUB_TEXT_BOOK } from 'config/typography';
import ChevronIcon from 'react-icons/lib/md/chevron-left';
import { Pdf, DocX } from 'components/svg/FileType';
import useInterval from 'hooks/useInterval';
import Button from 'components/ui/MButton';
import { StyledReactTooltip } from 'components/ToolTip';
import AlertDecagram from 'icons/AlertDecagram';
import toast from 'components/Snackbar/ToastUtilities';
import { b64toBlob, downloadBlob, formatBytesToSize } from 'utils/browser';
import { IDocumentProgressMetaData } from 'store/orders/types';
import CheckCircle from 'icons/CheckCircle';

type IItemProgress = {
  id: string;
  name: string;
  description?: string;
  timeStarted?: number;
  isMultisite?: boolean;
} & IDocumentProgressMetaData &
  Record<string, unknown>;

interface IDocumentGenerationProgress {
  title: string;
  selectorKey: string;
  items: IItemProgress[];
  getItemPendingStatus: (item: IItemProgress) => boolean;
  getItemReadyStatus: (item: IItemProgress) => boolean;
  fetchItemProgressStatus?: (item: IItemProgress) => Promise<void>;
  fetchItemBytes: (item: IItemProgress) => Promise<unknown>;
  clearItem: (item: IItemProgress) => void;
  trackItems: (items: IItemProgress[]) => void;
  onCompleteItemClick?: (item: IItemProgress) => void;
  showProgressModal?: boolean;
  errorText?: string;
  stopOnError?: boolean;
  bannerText?: string;
}

const RETRY_DELAY_MS = 10 * 1000; // 10 secs
const RETRY_TIMEOUT_MS = 2 * 60 * 1000; // 2 min

/** Tracks documents being generated and automatically trigger a printing or download request for any one of them that becomes available. */
const DocumentGenerationProgress = ({
  title,
  selectorKey,
  items,
  getItemPendingStatus,
  getItemReadyStatus,
  fetchItemProgressStatus,
  fetchItemBytes,
  clearItem,
  trackItems,
  onCompleteItemClick,
  showProgressModal = true,
  errorText,
  stopOnError,
  bannerText,
}: IDocumentGenerationProgress) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [delay, setDelay] = useState<number | null>(RETRY_DELAY_MS);
  const [saveAsDocs, setSaveAsDocs] = useState<Set<string>>(new Set());
  const [blobUrls, setBlobUrls] = useState<string[]>([]);

  const resetSaveAsDocs = useCallback(() => {
    setSaveAsDocs(new Set());
  }, [setSaveAsDocs]);

  const getDocumentStatus = async () => {
    const pendingDocuments = items.filter((item) => getItemPendingStatus(item));

    if (pendingDocuments.length > 0) {
      await Promise.all(
        pendingDocuments.map((item) => fetchDocumentStatus(item))
      );
    } else {
      setDelay(null);
    }
  };

  const fetchDocumentStatus = async (item: IItemProgress) => {
    try {
      if (item.status === 'Failed') {
        throw new Error('Failed');
      } else {
        if (!item.timeStarted) {
          trackItems([{ ...item, timeStarted: Date.now() }]);
        } else if (Date.now() - item.timeStarted > RETRY_TIMEOUT_MS) {
          throw new Error('Timeout');
        }

        if (fetchItemProgressStatus) await fetchItemProgressStatus(item);
      }
    } catch (error) {
      if (stopOnError) {
        clearItem(item);
        toast(item.errorText || errorText, 'error');
      } else {
        // Silence error here as it will be retried automatically after certain interval
      }
    }
  };

  useEffect(() => {
    setDelay(RETRY_DELAY_MS);
  }, [items.length]);

  useInterval(async () => {
    // continuously poll for document delivery status every certain interval
    if (items.length) {
      await getDocumentStatus();
    } else {
      setDelay(null);
    }
  }, delay);

  useEffect(() => {
    const readyDocuments = items.filter(
      (item) =>
        getItemReadyStatus(item) && !item.errored && !saveAsDocs.has(item.id)
    );

    if (readyDocuments.length > 0) {
      cleanOutObjectUrls();
      readyDocuments.forEach((item) => {
        fetchDocumentBytes(item);
      });
    } else {
      resetSaveAsDocs();
    }
  }, [items]);

  const cleanOutObjectUrls = () => {
    (document.querySelectorAll(`.${selectorKey}`) || []).forEach((element) => {
      window.URL.revokeObjectURL(element.getAttribute('src') || '');
    });
  };

  const fetchDocumentBytes = async (item: IItemProgress) => {
    try {
      setSaveAsDocs((prev) => new Set(prev.add(item.id)));
      const data = await fetchItemBytes(item);
      if (item.print && item.format === 'pdf') {
        const file = new Blob([data as BlobPart], {
          type: 'application/pdf',
        });
        const blobUrl = window.URL.createObjectURL(file);
        const pdfFrame = document.createElement('iframe');
        pdfFrame.id = `${selectorKey}-iframe`;
        pdfFrame.className = selectorKey;
        pdfFrame.src = blobUrl;
        pdfFrame.style.display = 'none';
        document.body.appendChild(pdfFrame);
        // this hack is for Firefox, on firefox immediate access to the iframe content window is not allowed for some reason.
        setTimeout(() => {
          pdfFrame.contentWindow?.print();
          clearItem(item);
        }, 1000);
        return;
      }
      if (item.download) {
        const formatMap = {
          pdf: 'application/pdf',
          docx:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };

        if (!isMultisite) {
          if (blobUrls.includes(item.id)) return;
          downloadBlob(
            b64toBlob(data, formatMap[item.format || 'pdf']),
            `${item.name}.${item.format}`
          );
          setBlobUrls((prev) => [...prev, item.id]);
        }

        if (!item.saveToCF) {
          clearItem(item);
          return;
        }
      }

      if (item.saveToCF) {
        setTimeout(() => {
          clearItem(item);
          // wait 30 seconds to close modal
        }, 30 * 1000);
        return;
      }
      clearItem(item);
    } catch (error) {
      if (stopOnError) {
        clearItem(item);
        toast(item.errorText || errorText, 'error');
      } else {
        trackItems([{ ...item, errored: true }]);
      }
    }
  };

  if (!showProgressModal || !items?.length) {
    return null;
  }

  const isMultisite = items?.[0].isMultisite;

  const isComplete = (item) => !item.errored && getItemReadyStatus(item);

  return (
    <FixedContainer>
      <Header>
        <Row justify="between" alignItems="center">
          <HeaderTitle>{title}</HeaderTitle>
          <StyledReactTooltip
            key={isExpanded ? 'expanded' : 'collapsed'}
            getContent={() =>
              isMultisite
                ? ''
                : isExpanded
                ? 'Minimize window'
                : 'Maximize window'
            }
            id={`${selectorKey}-toggle-popup-size-button`}
          />
          <ButtonContainer
            data-tip
            data-for={`${selectorKey}-toggle-popup-size-button`}
            onClick={() =>
              setIsExpanded((previouslyExpanded) => !previouslyExpanded)
            }
          >
            {isExpanded ? <ExpandedChevron /> : <CollapsedChevron />}
          </ButtonContainer>
        </Row>
        {isExpanded && bannerText && (
          <Banner>
            <AlertDecagram
              fill={primaryBlue8}
              style={{ width: '34px', height: '34px', marginRight: '14px' }}
            />
            <BannerText>{bannerText}</BannerText>
          </Banner>
        )}
      </Header>
      <DocumentsContainer>
        {isExpanded &&
          items.map((item) => {
            const size = isComplete(item)
              ? formatBytesToSize(item.bytes)
              : 'Exporting...';
            return (
              <DocumentProgressRow
                key={item.id}
                name={item.name}
                format={item.format}
                description={item.description || ''}
                size={size}
                isMultisite={isMultisite}
                loading={!item.errored && !getItemReadyStatus(item)}
                isComplete={isComplete(item)}
                showRetryButton={!stopOnError && !!item.errored}
                onCompleteClicked={() => {
                  onCompleteItemClick?.(item);
                }}
                onRetryClicked={() => {
                  trackItems([{ ...item, errored: false, fetching: false }]);
                  fetchDocumentBytes(item);
                }}
              />
            );
          })}
      </DocumentsContainer>
    </FixedContainer>
  );
};

export default DocumentGenerationProgress;

interface IDocumentProgressRow {
  name: string;
  description: string;
  format?: 'pdf' | 'docx';
  loading: boolean;
  showRetryButton: boolean;
  isComplete: boolean;
  onRetryClicked?: () => void;
  onCompleteClicked?: () => void;
  size?: string;
  isMultisite?: boolean;
}

const DocumentProgressRow = ({
  name,
  description,
  format,
  loading,
  showRetryButton,
  isComplete,
  onRetryClicked,
  onCompleteClicked,
  size,
  isMultisite,
}: IDocumentProgressRow) => {
  const iconStyle = { width: 24, height: 28 };

  return (
    <Row
      alignItems="center"
      style={{
        padding: '16px',
        cursor: isComplete && onCompleteClicked ? 'pointer' : '',
      }}
      onClick={() => {
        if (isComplete) {
          onCompleteClicked?.();
        }
      }}
      className="document-progress__item"
    >
      {format === 'docx' ? (
        <DocX style={iconStyle} />
      ) : (
        <Pdf style={iconStyle} />
      )}
      <Column style={{ marginLeft: '8px', flex: 1 }}>
        <Title
          className="document-progress__title"
          data-testid={`document-progress-title`}
        >
          <Truncate width={280} useCFTooltip>
            {name}
          </Truncate>
        </Title>
        {isMultisite && <SubTitle>{size}</SubTitle>}
        {description && (
          <SubTitle className="document-progress__description">
            <Truncate width={280} useCFTooltip>
              {description}
            </Truncate>
          </SubTitle>
        )}
      </Column>
      {loading && (
        <Spinner
          style={{ margin: '0', marginRight: '8px' }}
          color={primaryBlue9}
          size={24}
        />
      )}
      {isComplete && <CheckCircle fill={green8} />}
      {showRetryButton && (
        <Button variant="ghost" onClick={onRetryClicked}>
          RETRY
        </Button>
      )}
    </Row>
  );
};

// ===================
// STYLED COMPONENTS
// ===================

const FixedContainer = styled.div`
  width: 400px;
  z-index: ${headerZIndex};
  background-color: ${white};
  margin-left: 16px;
  align-self: end;
  border-radius: 3px;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12),
    0px 5px 6px rgba(0, 0, 0, 0.2);
`;

const Header = styled.div`
  padding: 10px 16px 10px;
  border-bottom: 2px solid ${primaryBlue2};
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.p`
  ${SMALL_BODY_MEDIUM}
`;

const Banner = styled.div`
  background-color: ${yellow4};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px;
  border-radius: 3px;
  margin-top: 4px;
`;

const BannerText = styled.p`
  ${SMALL_BODY_MEDIUM}
  color: ${primaryBlue8};
  letter-spacing: 0.0025em;
`;

const Title = styled.p`
    ${SMALL_BODY_MEDIUM}
    color: ${gray10}
`;

const SubTitle = styled.p`
    ${SUB_TEXT_BOOK}
    color: ${gray8};
    margin-top: -3px;
`;

const DocumentsContainer = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  & > div:not(:last-child) {
    border-bottom: 2px solid ${primaryBlue2};
  }
`;

const ButtonContainer = styled.div`
  width: 40px;
  height: 40px;

  text-align: center;
  padding-top: 5px;
  cursor: pointer;

  &:hover {
    background-color: rgba(52, 85, 128, 0.14);
    border-radius: 40px;
  }
`;

const StyledChevron = styled(ChevronIcon)`
  width: 30px;
  height: 30px;
  color: ${neutralGray7};
`;

const CollapsedChevron = styled(StyledChevron)`
  transform: rotate(90deg);
`;

const ExpandedChevron = styled(StyledChevron)`
  transform: rotate(270deg);
`;
