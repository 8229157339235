import workspaceAPI, {
  IJsonPatchOperation,
  JsonPatch,
} from 'services/workspaceAPI';
import { Dispatch, AsyncAction } from 'store';
import { formatDateTo } from 'utils/date';
import { addToast, setLoadingStateAction } from 'store/ui/actions';
import { toOrdinal } from 'utils/numbers';
import { SiteStatus } from 'components/SiteCard/types';
import {
  IAddress,
  ILoanAmount,
  ILoanAmountsHashTable,
  ILoanUpdatesHashTable,
  IOrder,
  IOrderClosingDateFields,
  IOrderProduct,
  IOrderPropertyFullAddress,
  IOwnerLiability,
  IProductionOffice,
  IReceiveOwnerLiabilities,
  IReceiveSalesPrices,
  ISalePrice,
  IService,
  ISetOrderAddresses,
  ISetOrderPropertiesAddresses,
  IUpdateCloseDateSuccess,
  IUpdateLoanInformationSuccess,
  IUpdateNameSuccess,
  IUpdateProductsSuccess,
  IUpdateServicesSuccess,
  OrderTypeKeys,
  Service,
  TransactionType,
  IOwningOffice,
  ProductionOfficeType,
  OrderLoadingKeys,
  IOrderBusinessParty,
} from '../types';
import { capitalizeSentence } from '../../../utils/text';
import { partial } from 'utils/functions';
import {
  fetchOrder,
  getOrderParties,
  getOrderProductionOffices,
  orderBusinesPartiesAction,
  orderReceiveAction,
} from './index';
import { AxiosError } from 'axios';
import { toast } from 'components/Snackbar';

function editNameSuccess(fileId: string, name: string): IUpdateNameSuccess {
  return {
    type: OrderTypeKeys.UPDATE_ORDER_NAME_SUCCESS,
    name,
    fileId,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function updateOrderName(fileId: string, name: string): AsyncAction<void> {
  return async (dispatch: Dispatch) => {
    const payload: JsonPatch = [
      {
        op: 'replace',
        path: '/name',
        value: name,
      },
    ];
    await workspaceAPI.patch(`/orders/${fileId}`, payload);
    dispatch(editNameSuccess(fileId, name));
  };
}

export function editCloseDateSuccess(
  fileId: string,
  closeDate: string,
  isCloseDateEstimated: boolean
): IUpdateCloseDateSuccess {
  return {
    type: OrderTypeKeys.UPDATE_ORDER_CLOSE_DATE_SUCCESS,
    closeDate,
    isCloseDateEstimated,
    fileId,
  };
}

const replaceOp = (
  value: string | null
): ((p: string) => IJsonPatchOperation) => (
  field:
    | 'disbursementDate'
    | 'estimatedSettlementDate'
    | 'prorateDate'
    | 'settlementDate'
): IJsonPatchOperation => ({
  op: 'replace',
  path: `/${field}`,
  value,
});

export const addOp = (
  obj: Omit<IJsonPatchOperation, 'op'>
): IJsonPatchOperation => {
  return {
    op: 'add',
    ...obj,
  };
};

const ECLIPSE_NULL_DATE = '0001-01-01';

function editLoanInformationSuccess(
  type,
  fileId: string,
  updatedLoanAmounts: ILoanAmount[]
): IUpdateLoanInformationSuccess {
  return {
    type,
    fileId,
    updatedLoanAmounts,
  };
}

const createLoanAndLiabilityUpdateRequest = (
  orderId: string,
  loanAmountId: string,
  { amount, liabilityAmount }
) => {
  const payload: JsonPatch = [];

  if (typeof amount !== 'undefined') {
    payload.push({
      op: 'replace',
      path: '/amount',
      value: amount,
    });
  }

  if (typeof liabilityAmount !== 'undefined') {
    payload.push({
      op: 'replace',
      path: '/liabilityAmount',
      value: liabilityAmount,
    });
  }

  return workspaceAPI.patch(
    `/orders/${orderId}/loan-amounts/${loanAmountId}`,
    payload
  );
};

function updateLoanAndLiability(
  originalLoanAmounts: ILoanAmountsHashTable,
  updatedLoanAmounts: ILoanUpdatesHashTable,
  fileId: string
): AsyncAction<void> {
  return (dispatch: Dispatch) => {
    let hasLoanUpdates = false;
    let hasLiabilityUpdates = false;

    const requests = Object.keys(updatedLoanAmounts).reduce((acc, loanId) => {
      const updatedLoanAmount =
        parseFloat(updatedLoanAmounts[loanId].amount) || 0;
      const shouldUpdateLoanAmount =
        originalLoanAmounts[loanId].amount !== updatedLoanAmount;

      const updatedLiabilityAmount =
        parseFloat(updatedLoanAmounts[loanId].liabilityAmount) || 0;
      const shouldUpdateLiabilityAmount =
        originalLoanAmounts[loanId].liabilityAmount !== updatedLiabilityAmount;

      if (shouldUpdateLoanAmount || shouldUpdateLiabilityAmount) {
        hasLoanUpdates = hasLoanUpdates || shouldUpdateLoanAmount;
        hasLiabilityUpdates =
          hasLiabilityUpdates || shouldUpdateLiabilityAmount;

        return [
          ...acc,
          createLoanAndLiabilityUpdateRequest(fileId, loanId, {
            amount: shouldUpdateLoanAmount ? updatedLoanAmount : undefined,
            liabilityAmount: shouldUpdateLiabilityAmount
              ? updatedLiabilityAmount
              : undefined,
          }),
        ];
      }
      return acc;
    }, []);

    return Promise.allSettled(requests).then((responses) => {
      const failedUpdates: string[] = [];
      const newLoanAmounts = responses.reduce((acc, response) => {
        if (response.status === 'fulfilled') {
          return [...acc, response.value.data];
        }

        const url = response.reason.config.url.split('/');
        const failedUpdateId: string = url[url.length - 1];

        failedUpdates.push(
          toOrdinal(originalLoanAmounts[failedUpdateId].sequenceNumber)
        );

        return acc;
      }, []);
      const totalUpdates = failedUpdates.length + newLoanAmounts.length;

      if (newLoanAmounts.length > 0) {
        dispatch(
          editLoanInformationSuccess(
            OrderTypeKeys.UPDATE_ORDER_LOAN_AND_LIABILITY,
            fileId,
            newLoanAmounts
          )
        );

        let toastMessage = '';

        if (hasLoanUpdates && hasLiabilityUpdates) {
          toastMessage =
            'Loan Amount & Lender Policy Liability successfully updated.';
        } else if (hasLoanUpdates && !hasLiabilityUpdates) {
          toastMessage = 'Loan Amount successfully updated.';
        } else {
          toastMessage = 'Lender Liability successfully updated.';
        }

        if (totalUpdates > 1) {
          const ordinalUpdatedValues = newLoanAmounts
            .reduce(
              (acc, { sequenceNumber }) => [...acc, toOrdinal(sequenceNumber)],
              []
            )
            .join(', ');

          toastMessage = `${ordinalUpdatedValues} ${toastMessage}`;
        }

        addToast(toastMessage, 'success')(dispatch);
      }

      if (failedUpdates.length > 0) {
        let toastMessage = '';

        if (hasLoanUpdates && hasLiabilityUpdates) {
          toastMessage =
            'Loan Amount & Lender Policy Liability could not be updated, please try again.';
        } else if (hasLoanUpdates && !hasLiabilityUpdates) {
          toastMessage = 'Loan Amount could not be updated, please try again.';
        } else {
          toastMessage =
            'Lender Liability could not be updated, please try again.';
        }

        if (totalUpdates > 1) {
          const ordinalFailedUpdateValues = failedUpdates.join(', ');

          toastMessage = `${ordinalFailedUpdateValues} ${toastMessage}`;
        }

        addToast(toastMessage, 'error')(dispatch);
      }
    });
  };
}

function updateOrderCloseDate(
  fileId: string,
  fields: IOrderClosingDateFields,
  prorationsToReCalculate?: { [key: string]: boolean }
) {
  return async (dispatch: Dispatch) => {
    const {
      estimatedClosingRawValue,
      estimatedDateIsActive,
      updatePolicies: { disbursementDate, prorateDate },
    } = fields;

    const payload: JsonPatch = [];
    const baseDateField = estimatedDateIsActive
      ? 'estimatedSettlementDate'
      : 'settlementDate';

    const closeDate =
      formatDateTo(estimatedClosingRawValue, 'YYYY-MM-DD') || ECLIPSE_NULL_DATE;
    const replaceDate = replaceOp(closeDate);
    payload.push(replaceDate(baseDateField));

    if (disbursementDate) payload.push(replaceDate('disbursementDate'));
    if (prorateDate) payload.push(replaceDate('prorateDate'));

    if (prorationsToReCalculate) {
      const baseProrationFlag = '/prorationFlag';
      payload.push(addOp({ path: baseProrationFlag, value: {} }));

      Object.entries(prorationsToReCalculate).forEach(([proration, value]) =>
        payload.push(
          addOp({ path: `${baseProrationFlag}/${proration}`, value })
        )
      );
    }

    await workspaceAPI
      .patch(`/orders/${fileId}/closing-dates`, payload)
      .then(({ data }) => {
        const isEstimated =
          !data.settlementDate && !!data.estimatedSettlementDate;
        const date = isEstimated
          ? data.estimatedSettlementDate
          : data.settlementDate;

        dispatch(editCloseDateSuccess(fileId, date ?? '', isEstimated));
      });
  };
}

function updateProducts(
  fileId: string,
  newProducts: IOrderProduct[],
  shouldDisplaySuccessToast = true
): AsyncAction<void> {
  return async (dispatch: Dispatch) => {
    try {
      await workspaceAPI.put(`/orders/${fileId}/products`, newProducts);
      dispatch({
        type: OrderTypeKeys.UPDATE_ORDER_PRODUCTS_SUCCESS,
        fileId: fileId,
        products: newProducts,
      } as IUpdateProductsSuccess);
      if (shouldDisplaySuccessToast) {
        addToast('Products successfully updated.', 'success')(dispatch);
      }
    } catch (error) {
      addToast(
        'Products could not be updated, please try again',
        'error'
      )(dispatch);
    }
  };
}

function getSalesPrice(fileId: string): AsyncAction<void> {
  return async (dispatch: Dispatch) => {
    return workspaceAPI
      .get(`/orders/${fileId}/sales-prices`)
      .then(({ data }) => {
        const newTotalSalesPrice = data.reduce(
          (acc: number, { amount }) => acc + (amount || 0),
          0
        );
        dispatch({
          type: OrderTypeKeys.RECEIVE_SALES_PRICE,
          payload: {
            salesPrices: data,
            totalSalesPrice: newTotalSalesPrice,
          },
        });
      })
      .catch((e) => e);
  };
}

function editSalesPrices(
  fileId: string,
  salesPrices: ISalePrice[],
  updateBrokerCommission = true
) {
  const data: { [key: string]: any } = { salesPrices, updateBrokerCommission };

  return workspaceAPI.put(`/orders/${fileId}/sales-prices`, data);
}

function editOwnerLiability(
  fileId: string,
  ownerLiabilities: IOwnerLiability[]
) {
  return workspaceAPI.put(
    `/orders/${fileId}/owner-liabilities`,
    ownerLiabilities
  );
}

export const receiveSalesPrices = (
  salesPrices: ISalePrice[],
  totalSalesPrice?: number
): IReceiveSalesPrices => ({
  type: OrderTypeKeys.RECEIVE_SALES_PRICE,
  payload: {
    salesPrices,
    totalSalesPrice,
  },
});

export const receiveOwnerLiabilities = (
  ownerLiabilities: IOwnerLiability[]
): IReceiveOwnerLiabilities => ({
  type: OrderTypeKeys.RECEIVE_OWNER_LIABILITIES,
  payload: {
    ownerLiabilities,
  },
});

export const getUpdateSaleActionMessage = (
  actionMsg: string,
  {
    ownerLiabilityUpdated,
    realEstateCommissionUpdated,
  }: {
    ownerLiabilityUpdated: boolean;
    realEstateCommissionUpdated: boolean;
  }
) => {
  const updatedEntities: string[] = [];
  if (ownerLiabilityUpdated) {
    updatedEntities.push('Owner Liability');
  }
  if (realEstateCommissionUpdated) {
    updatedEntities.push('Real Estate Broker Commission');
  }

  let msg = 'Total Sales Price';

  if (Boolean(updatedEntities.length)) {
    const restOfMsg = updatedEntities.reduce((acc, t, idx) => {
      const isLast = idx === updatedEntities.length - 1;
      return (acc += `${isLast ? ' &' : ','} ${t}`);
    }, '');

    msg = `${msg}${restOfMsg}`;
  }

  return `${msg} ${actionMsg}`;
};

function updateSalesInformation(
  fileId: string,
  salesPrices: ISalePrice[],
  order: IOrder,
  updateOwnerLiabilities = false,
  updateRealEstateBrokerCommission?
): AsyncAction<void> {
  return async (dispatch: Dispatch) => {
    let didUpdateSalesPrices = false;
    let didUpdateOwnerLiabilities = false;

    const newTotalSalesPrice = salesPrices.reduce(
      (acc: number, { amount }) => acc + (amount || 0),
      0
    );
    const updatedOwnerLiabilities = order.ownerLiabilities.map(
      (liability, index) => {
        if (index === 0) {
          return {
            ...liability,
            amount: newTotalSalesPrice,
          };
        }

        return liability;
      }
    );

    return editSalesPrices(
      fileId,
      salesPrices,
      updateRealEstateBrokerCommission
    )
      .then(() => {
        didUpdateSalesPrices = true;

        if (updateOwnerLiabilities) {
          return editOwnerLiability(fileId, updatedOwnerLiabilities);
        }
      })
      .then(() => {
        if (updateOwnerLiabilities) {
          didUpdateOwnerLiabilities = true;
        }
      })
      .catch(() => {
        // We handle errors through toast notifications, but we want to catch them
      })
      .finally(() => {
        if (didUpdateSalesPrices) {
          dispatch(receiveSalesPrices(salesPrices, newTotalSalesPrice));
        }

        if (didUpdateOwnerLiabilities) {
          dispatch(receiveOwnerLiabilities(updatedOwnerLiabilities));
        }

        //toast messages
        const successMsg = getUpdateSaleActionMessage('successfully updated.', {
          ownerLiabilityUpdated: didUpdateOwnerLiabilities,
          realEstateCommissionUpdated: updateRealEstateBrokerCommission,
        });

        if (didUpdateSalesPrices) {
          addToast(successMsg, 'success')(dispatch);

          if (updateOwnerLiabilities && !didUpdateOwnerLiabilities) {
            addToast(
              'Owner Liability could not be updated, please try again.',
              'error'
            )(dispatch);
          }

          return;
        }

        const errorMsg = getUpdateSaleActionMessage(
          'could not be updated, please try again.',
          {
            ownerLiabilityUpdated: updateOwnerLiabilities,
            realEstateCommissionUpdated: updateRealEstateBrokerCommission,
          }
        );

        return addToast(errorMsg, 'error')(dispatch);
      });
  };
}

const updateOwnerLiability = (
  fileId: string,
  ownerLiabilities: IOwnerLiability[]
): AsyncAction<void> => {
  return async (dispatch: Dispatch) =>
    editOwnerLiability(fileId, ownerLiabilities)
      .then(() => {
        dispatch(receiveOwnerLiabilities(ownerLiabilities));

        return addToast(
          'Owner Liability successfully updated.',
          'success'
        )(dispatch);
      })
      .catch(() => {
        return addToast(
          'Owner Liability could not be updated.',
          'error'
        )(dispatch);
      });
};

function editOrderStatus(fileId: string, status: SiteStatus) {
  return workspaceAPI.put(`/orders/${fileId}/_status`, {
    status,
  });
}

const updateOrderStatus = (
  fileId: string,
  status: SiteStatus
): AsyncAction<void> => {
  return async (dispatch: Dispatch) =>
    editOrderStatus(fileId, status)
      .then(() => {
        dispatch({
          type: OrderTypeKeys.RECEIVE_ORDER_STATUS,
          payload: {
            status,
          },
        });

        return addToast('Status successfully updated.', 'success')(dispatch);
      })
      .catch(() => {
        return addToast('Status could not be updated.', 'error')(dispatch);
      });
};

async function removeOrderService(
  currentServices: IService[],
  fileId: string,
  fileServiceId: number
) {
  await workspaceAPI.delete(`/orders/${fileId}/services/${fileServiceId}`);
  currentServices.splice(
    currentServices.findIndex((s) => s.fileServiceId === fileServiceId),
    1
  );
}

async function addOrderService(
  currentServices: IService[],
  fileId: string,
  service: Service
) {
  const response = await workspaceAPI.post(`/orders/${fileId}/services/`, {
    serviceType: capitalizeSentence(service),
  });
  currentServices.push({
    description: response.data.description || capitalizeSentence(service),
    fileServiceId: response.data.fileServiceId,
  });
}

/**
 * This function determines the order in which services need to be added and removed and updates them.
 *
 * Service has the following rules: All 3 services cannot be on an order at the same time, and at least Title or Escrow must be present.
 * The backend endpoints only allow for removal or addition of single services.
 * This has implications on the order we need to send the requests. For example, to change from Title to Escrow we must add
 * escrow before removing title, otherwise we'll gat an error stating that at least one of them must be present.
 * Similarly if an order has Sub Escrow and Title, and changes to Escrow and Sub Escrow, we must first remove Sub Escrow,
 * otherwise we get an error saying the three services cannot be present. Then we need to add Escrow and remove Title (same reason as before) and then
 * we need to add the Sub Escrow service again.
 * @param initialOrderServices Services on the order before modifying
 * @param newServices The new services that should be included on the order
 * @summary Determines the order in which services need to be added or removed and updates them.
 * @returns  The list of service after all the request have been made.
 */
async function updateOrderServices(
  initialOrderServices: IService[],
  newServices: Service[],
  fileId: string
) {
  const currentServices = [...initialOrderServices];
  const addService = partial(addOrderService, currentServices, fileId);
  const removeService = partial(removeOrderService, currentServices, fileId);

  try {
    const servicesToRemove = initialOrderServices.filter(
      (s) => !newServices.some((ns) => ns === s.description.toLowerCase())
    );
    const removesSubEscrow = servicesToRemove.some(
      (s) => s.description.toLowerCase() === 'sub escrow'
    );
    const servicesToAdd = newServices.filter(
      (s) =>
        !initialOrderServices.some((cs) => cs.description.toLowerCase() === s)
    );
    const addsMainService = servicesToAdd.some((s) => s !== 'sub escrow');
    const orderSubEscrow = initialOrderServices.find(
      (s) => s.description.toLowerCase() === 'sub escrow'
    );

    const transientRemovesSubEscrow = addsMainService && orderSubEscrow;
    if (removesSubEscrow || transientRemovesSubEscrow) {
      await removeService(orderSubEscrow!.fileServiceId);
    }

    if (addsMainService) {
      const addedMainService = servicesToAdd.find((s) => s !== 'sub escrow');
      await addService(addedMainService);
    }

    const removesMainService = servicesToRemove.some(
      (s) => s.description.toLowerCase() !== 'sub escrow'
    );
    if (removesMainService) {
      const removedMainService = servicesToRemove.find(
        (s) => s.description.toLowerCase() !== 'sub escrow'
      );
      await removeService(removedMainService?.fileServiceId);
    }

    const addsSubEscrow = servicesToAdd.find((s) => s === 'sub escrow');
    if (addsSubEscrow || (transientRemovesSubEscrow && !removesSubEscrow)) {
      await addService('sub escrow');
    }
  } catch (e) {
    return {
      currentServices,
      error: e as AxiosError,
    };
  }
  return {
    currentServices,
    error: null,
  };
}

function updateServices(
  initialOrderServices: IService[],
  newServices: Service[],
  fileId: string
): AsyncAction<{ success: boolean; error?: AxiosError | null }> {
  return async (dispatch: Dispatch) => {
    try {
      const result = await updateOrderServices(
        initialOrderServices,
        newServices,
        fileId
      );
      // if we fail to refresh the order data, we can still relay on
      // the services that were modified locally
      let services = result.currentServices;
      let offices:
        | Array<IProductionOffice | IOwningOffice>
        | undefined = undefined;
      //If we can're refresh the order data, fail silently
      const response = await fetchOrder(fileId).catch();
      dispatch(orderReceiveAction(response.data, true));

      offices = response?.data?.offices;
      services = response?.data?.services ?? services;

      // get the available officers names
      const availableOfficers = [
        'Escrow Officer',
        'Escrow Assistant',
        'Title Officer',
        'Title Assistant',
      ];

      // update the state with the data of only selected rows.
      let updatedParties: IOrderBusinessParty[] = [];

      availableOfficers.forEach((service: string) => {
        const currentParties =
          response.data?.businessPartyAndEmployees?.filter(
            (emp) => emp.role == service
          ) || [];
        updatedParties = [...updatedParties, ...currentParties];
      });

      // Update parties based on the header selected
      dispatch(orderBusinesPartiesAction(updatedParties));

      /* Fetch production offices */
      const productionOfficesToFetch = services
        .filter(({ description }) => ['Escrow', 'Title'].includes(description)) // Filter out services that are not Title or Escrow
        .map(({ description }) =>
          dispatch(
            getOrderProductionOffices(
              fileId,
              description.toLocaleLowerCase() as ProductionOfficeType
            )
          )
        );

      let errorOnFetchProductionOffices = false;
      await Promise.all(productionOfficesToFetch).catch(
        () => (errorOnFetchProductionOffices = true)
      );
      /* End of fetch production offices */

      dispatch({
        type: OrderTypeKeys.UPDATE_ORDER_SERVICES_COMPLETE,
        fileId,
        services,
        offices,
      } as IUpdateServicesSuccess);
      await getOrderParties(fileId)(dispatch);

      if (!result.error) {
        const message = 'Service successfully updated.';
        addToast(message, 'success')(dispatch);

        // Show the error toast after the Services update toast
        if (errorOnFetchProductionOffices)
          toast.error(
            'Failed to fetch production offices, please refresh your page'
          );
      } else {
        const defaultMessage = 'Service could not be updated, please try again';
        const responseMessage = result.error.response?.data?.description ?? '';
        const message =
          result.error.response?.status === 409 && responseMessage
            ? responseMessage
            : defaultMessage;
        addToast(message, 'error')(dispatch);
      }

      return {
        success: !result.error,
        error: result.error,
      };
    } catch (e) {
      return {
        success: false,
        error: e as AxiosError,
      };
    }
  };
}

function getOrderPropertiesAddresses(orderId: string | number) {
  return (dispatch: Dispatch) => {
    dispatch(
      setLoadingStateAction(OrderLoadingKeys.orderFileDetailsLoading, true)
    );
    return workspaceAPI
      .get(`/orders/${orderId}/addresses`)
      .then(({ data }) => {
        dispatch({
          type: OrderTypeKeys.SET_ORDER_PROPERTIES_ADDRESSES,
          payload: {
            addresses: (data ?? []) as IOrderPropertyFullAddress[],
          },
        } as ISetOrderPropertiesAddresses);
        return data;
      })
      .finally(() => {
        dispatch(
          setLoadingStateAction(OrderLoadingKeys.orderFileDetailsLoading, false)
        );
      });
  };
}

const transformAddress = (address: IOrderPropertyFullAddress): IAddress => ({
  addressId: address.addressId,
  city: address.city || '',
  country: address.country || '',
  county: address.county || '',
  fastId: address.fastId ?? undefined,
  propertyType: address.propertyType || '',
  seqNum: address.seqNum ?? undefined,
  state: address.state || '',
  street: address.street ?? undefined,
  zip: address.zip || '',
});

function setOrderAddresses(addresses: IOrderPropertyFullAddress[]) {
  return (dispatch: Dispatch) => {
    const transformedAddresses: IAddress[] = addresses.map(transformAddress);

    dispatch({
      type: OrderTypeKeys.SET_ORDER_ADDRESSES,
      payload: {
        addresses: transformedAddresses,
      },
    } as ISetOrderAddresses);
  };
}

function updateOrderType(
  fileId: string,
  transactionType: TransactionType,
  shouldDisplaySuccessToast = true
): AsyncAction<boolean> {
  return async (dispatch: Dispatch) => {
    try {
      await workspaceAPI.put<{
        transactionType: TransactionType;
      }>(`/orders/${fileId}/_transaction-type`, {
        transactionType: transactionType,
      });
      dispatch({
        type: OrderTypeKeys.UPDATE_ORDER_TYPE_SUCCESS,
        fileId: fileId,
        transactionType: transactionType,
      });
      if (shouldDisplaySuccessToast) {
        addToast('Order Type Successfully updated.', 'success')(dispatch);
      }
      return true;
    } catch (error) {
      const defaultMessage = 'Order Type could not be updated.';
      const responseMessage = error.response?.data?.description ?? '';
      const message =
        error.response?.status === 409 && responseMessage
          ? responseMessage
          : defaultMessage;
      addToast(message, 'error')(dispatch);
      return false;
    }
  };
}

export {
  getOrderPropertiesAddresses,
  getSalesPrice,
  setOrderAddresses,
  updateLoanAndLiability,
  updateOrderCloseDate,
  updateOrderName,
  updateOrderStatus,
  updateOwnerLiability,
  updateProducts,
  updateSalesInformation,
  updateServices,
  updateOrderType,
};
