import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, IApplicationState } from 'store';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { readCookie } from 'utils/cookies';
import * as authUtils from 'utils/auth';
import { getSession } from 'store/auth/actions';
import { UserType } from 'store/auth/types';
import { extractQueryParams } from 'utils/url';
import SendVerificationEmail from 'views/SendVerificationEmail';

interface IAuthRouteProps {
  authenticated: boolean;
  authView: React.ReactNode | null;
  dispatch: Dispatch;
  emailVerificationExpired: boolean;
  guestView: React.ReactNode | null;
  loading: boolean;
  userType?: UserType;
}

class AuthRoute extends React.Component<IAuthRouteProps> {
  public componentDidMount() {
    const { authenticated, dispatch, userType } = this.props;
    const userTypeCookie = readCookie('userType') || userType;

    // use rel=employee or rel=customer to determine which login screen to send the user to
    const {
      values: { rel, emailCode },
      restSearchParams,
    } = extractQueryParams(location.search, ['rel', 'emailCode']);

    if (
      !authenticated &&
      !authUtils.getAfterLoginRedirectPath() &&
      location.pathname !== '/'
    ) {
      // unconditionally set the path to redirect to upon successful login
      // let the routes themselves handle validation
      const path = location.pathname;

      const redirectPath = restSearchParams.includes('ReceiptToken')
        ? '/billing'
        : `${path}${restSearchParams}`;

      authUtils.setAfterLoginRedirectPath(redirectPath);
    }

    if (rel === 'customer') {
      window.location.href = `${AppConfig.workspaceBackendUrl}/external/signin${
        emailCode ? `?emailCode=${emailCode}` : ''
      }`;
      return;
    }

    // NOTE: Initially to deal with internal users on a
    // fresh signin we will direct the to `/employee` route
    // as for now its the only way we can detect internal
    // vs external.
    if (window.location.pathname !== '/error/auth') {
      if (
        userTypeCookie === UserType.Internal ||
        window.location.pathname === '/employee' ||
        rel === 'employee'
      ) {
        dispatch(getSession({ type: UserType.Internal }));
      } else {
        dispatch(getSession({ type: '' }));
      }
    }
  }

  public renderRoute = (routeProps: RouteComponentProps<any>) => {
    const {
      loading,
      authenticated,
      authView,
      guestView,
      emailVerificationExpired,
    } = this.props;

    const AuthComponent = authView as any;
    const GuestComponent = guestView as any;

    if (authenticated || emailVerificationExpired)
      // special-case this route since it is for when a user's email verification
      // has expired. In such case, all API requests (including GET /_me) will
      // return a 403, and there is no point in trying to render app content.
      return (
        <Switch>
          <Route
            exact
            path="/sign-in-access-control"
            component={SendVerificationEmail}
          />
          <Route
            render={() => {
              if (emailVerificationExpired) {
                // Immediately and without warning redirect to sign-in access control page.
                return <Redirect to="/sign-in-access-control" />;
              }
              return <AuthComponent />;
            }}
          />
        </Switch>
      );

    if (loading) return null;

    return <GuestComponent {...routeProps} />;
  };

  public render() {
    const { authView: _, guestView: __, ...rest } = this.props;

    return <Route {...rest} render={this.renderRoute} />;
  }
}

function mapStateToProps(state: IApplicationState) {
  const { loading, session } = state.auth;
  const { type: userType, authenticated, emailVerificationExpired } = session;

  return {
    authenticated,
    emailVerificationExpired,
    userType,
    loading,
  };
}

export default connect(mapStateToProps)(AuthRoute);
